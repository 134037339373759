var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manage-payment" }, [
    _c("div", { staticClass: "container-title" }, [_vm._v("MANAGE PAYMENTS")]),
    _c("div", { staticClass: "payment-disbursement" }, [
      _vm._v(
        "\n    How would you like to manage disbursement of payments to members of the\n    Product Team?\n  "
      ),
    ]),
    _c("div", { staticClass: "payment-disbursement" }, [
      _vm._v("\n    Switch the toggle below to change the setting.\n  "),
    ]),
    _c("div", { staticClass: "toggle-wrapper" }, [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              _vm.autoPayment = !_vm.autoPayment
            },
          },
        },
        [
          _vm.autoPayment
            ? _c("img", {
                attrs: { src: _vm.imageLink + "active-toggle.svg", alt: "" },
              })
            : _c("img", {
                attrs: { src: _vm.imageLink + "inactive-toggle.svg", alt: "" },
              }),
        ]
      ),
      _c("div", { staticStyle: { "margin-left": "8px" } }, [
        _vm._v("Automatic Payment"),
      ]),
    ]),
    _vm.autoPayment
      ? _c("div", { staticClass: "payment-info" }, [
          _vm._v("\n    Payments would be disbursed automatically\n  "),
        ])
      : _c("div", { staticClass: "payment-info" }, [
          _vm._v("Payments would be disbursed manually"),
        ]),
    _c("div", { staticClass: "divider-horizontal" }),
    _c(
      "div",
      {
        staticClass: "payment-disbursement",
        style: !_vm.autoPayment ? "opacity: 0.3;" : null,
      },
      [
        _vm._v(
          "\n    Set which percentage of total payments made automatically gets sent to\n    each teammate.\n  "
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "team-members-list",
        style: !_vm.autoPayment ? "opacity: 0.3;" : null,
      },
      _vm._l(_vm.teamMembers, function (member, i) {
        return _c("div", { key: i, staticClass: "members-box" }, [
          _c("div", { staticClass: "team-member" }, [
            _c(
              "div",
              { staticClass: "profile-image" },
              [
                _c("v-avatar", {
                  staticClass: "profile_bg",
                  style: {
                    backgroundImage:
                      "url(" +
                      (_vm.$imageUrl + member.profile_picture || _vm.test) +
                      ")",
                  },
                  attrs: { size: 40, color: "grey lighten-4" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "dev-details" }, [
              _c("div", { staticClass: "dev-name" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(member.name ? member.name : "") +
                    "\n            "
                ),
                _vm.currentUser.id == member.id
                  ? _c("span", [_vm._v(" (You)")])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "dev-role" }, [
                _vm._v(_vm._s(member.team_role)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "percentage-textbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.teammateCut["percent" + i],
                  expression: "teammateCut['percent' + i]",
                },
              ],
              staticClass: "percent-textbox",
              attrs: { type: "text" },
              domProps: { value: _vm.teammateCut["percent" + i] },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.teammateCut, "percent" + i, $event.target.value)
                },
              },
            }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }