var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [_vm._v("Swap Role")]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "swap-msg" }, [
          _vm._v(
            "\n        Swap role between teammates’. Note that this also swaps all the tasks\n        done and assigned between the teammates.\n      "
          ),
        ]),
        _c("div", { staticClass: "current-member" }, [
          _c(
            "div",
            { staticClass: "developer-icon" },
            [
              _c("v-avatar", {
                staticClass: "profile_bg",
                style: {
                  backgroundImage:
                    "url(" +
                    (_vm.$imageUrl + _vm.swapMember.profile_picture ||
                      _vm.imageAvatartest) +
                    ")",
                },
                attrs: { size: 20, color: "grey lighten-4" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "developer-name" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.swapMember.name ? _vm.swapMember.name : "") +
                "\n          "
            ),
            _c("span", [_vm._v("(" + _vm._s(_vm.swapMember.team_role) + ")")]),
          ]),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "new-members" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedMember,
                  expression: "selectedMember",
                },
              ],
              staticClass: "select-cont",
              attrs: { name: "role" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedMember = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.teamMembers, function (member, i) {
              return _c(
                "option",
                {
                  key: i,
                  domProps: {
                    selected: i == 0 ? "selected" : "",
                    value: member.id,
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(member.name ? member.name : "") +
                      "\n          "
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          { staticClass: "grupa-white-btn", on: { click: _vm.closeDialog } },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            staticStyle: { "margin-left": "8px" },
          },
          [_vm._v("Proceed")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "swap-icon" }, [
      _c("img", { attrs: { src: "/img/dashboard/flip-icon.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }