<template>
  <div class="manage-payment">
    <div class="container-title">MANAGE PAYMENTS</div>
    <div class="payment-disbursement">
      How would you like to manage disbursement of payments to members of the
      Product Team?
    </div>
    <div class="payment-disbursement">
      Switch the toggle below to change the setting.
    </div>
    <div class="toggle-wrapper">
      <div @click="autoPayment = !autoPayment">
        <img v-if="autoPayment" :src="imageLink + 'active-toggle.svg'" alt="" />
        <img v-else :src="imageLink + 'inactive-toggle.svg'" alt="" />
      </div>
      <div style="margin-left: 8px">Automatic Payment</div>
    </div>
    <div v-if="autoPayment" class="payment-info">
      Payments would be disbursed automatically
    </div>
    <div v-else class="payment-info">Payments would be disbursed manually</div>
    <div class="divider-horizontal"></div>
    <div
      class="payment-disbursement"
      :style="!autoPayment ? 'opacity: 0.3;' : null"
    >
      Set which percentage of total payments made automatically gets sent to
      each teammate.
    </div>
    <div
      class="team-members-list"
      :style="!autoPayment ? 'opacity: 0.3;' : null"
    >
      <div class="members-box" v-for="(member, i) in teamMembers" :key="i">
        <div class="team-member">
          <div class="profile-image">
            <v-avatar
              :size="40"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + member.profile_picture || test
                })`,
              }"
            ></v-avatar>
          </div>
          <div class="dev-details">
            <div class="dev-name">
              {{ member.name ? member.name : "" }}
              <span v-if="currentUser.id == member.id"> (You)</span>
            </div>
            <div class="dev-role">{{ member.team_role }}</div>
          </div>
        </div>
        <div class="percentage-textbox">
          <input
            type="text"
            v-model="teammateCut['percent' + i]"
            class="percent-textbox"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teamMembers: {
      type: Array,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  components: {},

  data: () => ({
    imageLink: "/img/dashboard/workmode/",
    autoPayment: true,
    test: "/img/profile.svg",
    teammateCut: {
      percent: "",
    },
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.manage-payment {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  text-align: left;
}
.container-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.toggle-wrapper,
.payment-disbursement {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
  width: 442px;
  cursor: pointer;
}
/* .team-members-list, */
.toggle-wrapper {
  display: flex;
}
.payment-info {
  color: #1fa564;
  margin-top: 12px;
  font-size: 12px;
  line-height: 140%;
}
.divider-horizontal {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.members-box {
  display: flex;
  margin-top: 24px;
  width: 340px;
  /* min-width: 340px; */
  justify-content: space-between;
}
.team-member {
  display: flex;
  /* width: 220px; */
}
.dev-details {
  margin-left: 16px;
}
.dev-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.dev-role {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 4px;
}
.profile_bg {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-position: top;
}
.percent-textbox {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  width: 76px;
  height: 40px;
  padding: 11px;
  text-align: center;
}
.percent-textbox:focus {
  outline: none;
}
</style>
