<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">Swap Role</div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="swap-msg">
          Swap role between teammates’. Note that this also swaps all the tasks
          done and assigned between the teammates.
        </div>
        <div class="current-member">
          <div class="developer-icon">
            <v-avatar
              :size="20"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + swapMember.profile_picture || imageAvatartest
                })`,
              }"
            ></v-avatar>
          </div>
          <div class="developer-name">
            {{ swapMember.name ? swapMember.name : "" }}
            <span>({{ swapMember.team_role }})</span>
          </div>
        </div>
        <div class="swap-icon">
          <img src="/img/dashboard/flip-icon.svg" alt="" />
        </div>
        <div class="new-members">
          <select class="select-cont" name="role" v-model="selectedMember">
            <option
              :selected="i == 0 ? 'selected' : ''"
              :value="member.id"
              v-for="(member, i) in teamMembers"
              :key="i"
            >
              {{ member.name ? member.name : "" }}
            </option>
          </select>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
        <button style="margin-left: 8px" class="grupa-blue-btn">Proceed</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
    swapMember: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      test: "/img/profile.svg",
      selectedMember: "",
    };
  },
  created() {
    this.selectedMember = this.teamMembers[0].id;
  },
  methods: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
  width: 356px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
  width: 356px;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
.swap-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.current-member {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
  display: flex;
}
.developer-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 8px;
  margin-top: 2px;
}
.developer-name span {
  color: #979da5;
}
.swap-icon {
  text-align: center;
  margin-top: 16px;
}
.select-cont {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  margin-top: 16px;
  height: 50px;
}
</style>


