var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v("Remove/Replace Teammate"),
        ]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "replace-msg" }, [
          _vm._v(
            "\n        You are about to remove\n        " +
              _vm._s(_vm.removeMember.name ? _vm.removeMember.name : "") +
              " from this project.\n      "
          ),
        ]),
        _c(
          "div",
          { staticClass: "replace-msg", staticStyle: { "margin-top": "24px" } },
          [_vm._v("\n        Select a replacement to continue.\n      ")]
        ),
        _c("div", { staticClass: "new-members" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.replacement,
                  expression: "replacement",
                },
              ],
              staticClass: "select-cont",
              attrs: { name: "role" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.replacement = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.otherMembers, function (member, i) {
              return _c(
                "option",
                {
                  key: i,
                  domProps: {
                    selected: i == 0 ? "selected" : "",
                    value: member.id,
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(member.name ? member.name : "") +
                      "\n            (" +
                      _vm._s(member.team_role) +
                      ")\n          "
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          { staticClass: "grupa-white-btn", on: { click: _vm.closeDialog } },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-red-btn",
            staticStyle: { "margin-left": "8px" },
            on: { click: _vm.handleReplaceMember },
          },
          [_vm._v("\n        Proceed\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }