<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">Remove/Replace Teammate</div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="replace-msg">
          You are about to remove
          {{ removeMember.name ? removeMember.name : "" }} from this project.
        </div>
        <div class="replace-msg" style="margin-top: 24px">
          Select a replacement to continue.
        </div>
        <div class="new-members">
          <select class="select-cont" name="role" v-model="replacement">
            <option
              :selected="i == 0 ? 'selected' : ''"
              :value="member.id"
              v-for="(member, i) in otherMembers"
              :key="i"
            >
              {{ member.name ? member.name : "" }}
              ({{ member.team_role }})
            </option>
          </select>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
        <button
          style="margin-left: 8px"
          class="grupa-red-btn"
          @click="handleReplaceMember"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    removeMember: {
      type: Object,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
    otherMembers: {
      type: Array,
      required: true,
    },
    handleReplaceMember: {
      type: Function,
      required: true,
    },
    replacement: {
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
  width: 356px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
  width: 356px;
}
.close-btn {
  cursor: pointer;
}
.dialog-content {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.select-cont {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  margin-top: 16px;
  height: 50px;
}
</style>


