var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-team-container" },
    [
      _c("div", { staticClass: "container-title" }, [
        _vm._v("MANAGE YOUR TEAM"),
      ]),
      _c(
        "div",
        { staticClass: "developers-wrapper" },
        _vm._l(_vm.teamMembers, function (member, i) {
          return _c("div", { key: i, staticClass: "developer-card" }, [
            _c("div", { staticClass: "team-details" }, [
              _c(
                "div",
                { staticClass: "image-wrapper" },
                [
                  _c("v-avatar", {
                    staticClass: "profile_bg",
                    style: {
                      backgroundImage:
                        "url(" +
                        (_vm.$imageUrl + member.profile_picture ||
                          _vm.imageAvatar) +
                        ")",
                    },
                    attrs: { size: 64, color: "grey lighten-4" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "details-wrap" }, [
                _c("div", { staticClass: "dev-name" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(member.name ? member.name : "") +
                      "\n            "
                  ),
                  _vm.currentUser.id == member.id
                    ? _c("span", [_vm._v(" (You)")])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "dev-role" }, [
                  _vm._v(_vm._s(member.team_role)),
                ]),
                _c("div", { staticClass: "dev-email" }, [
                  _vm._v(_vm._s(member.email)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "card-action" }, [
              _c("div", { staticClass: "text-action view-profile" }, [
                _vm._v("View Profile"),
              ]),
              _c(
                "div",
                {
                  staticClass: "text-action swap-role",
                  on: {
                    click: function ($event) {
                      return _vm.handleSwapDev(member)
                    },
                  },
                },
                [_vm._v("\n          Swap Role\n        ")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-action remove-dev",
                  on: {
                    click: function ($event) {
                      return _vm.handleRemoveDev(member)
                    },
                  },
                },
                [_vm._v("\n          Remove/Replace\n        ")]
              ),
            ]),
          ])
        }),
        0
      ),
      _vm.isSwapModal
        ? _c(
            "SwapTeamMember",
            _vm._b(
              {},
              "SwapTeamMember",
              {
                closeDialog: _vm.closeDialog,
                teamMembers: _vm.teamMembers,
                swapMember: _vm.swapMember,
                handleSwapMember: _vm.handleSwapMember,
              },
              false
            )
          )
        : _vm._e(),
      _vm.isRemoveModal
        ? _c(
            "RemoveTeamMember",
            _vm._b(
              {},
              "RemoveTeamMember",
              {
                closeDialog: _vm.closeDialog,
                teamMembers: _vm.teamMembers,
                removeMember: _vm.removeMember,
                handleReplaceMember: _vm.handleReplaceMember,
                otherMembers: _vm.otherMembers,
                replacement: _vm.replacement,
                spinner: _vm.spinner,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }