<template>
  <div class="team-mgt-wrapper">
    <TeamMembers v-bind="{ teamMembers, currentUser }" />
    <ManagePayment v-bind="{ teamMembers, currentUser }" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TeamMembers from "./teamMembers";
import ManagePayment from "./managePayment";
export default {
  props: {},
  components: {
    TeamMembers,
    ManagePayment,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["teamInfo", "singleProjectTeam"]),
    teamMembers() {
      return this.singleProjectTeam;
    },
    currentUser() {
      return this.teamInfo.user;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.team-mgt-wrapper {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
  padding: 32px;
}
</style>
