<template>
  <div class="manage-team-container">
    <div class="container-title">MANAGE YOUR TEAM</div>
    <div class="developers-wrapper">
      <div class="developer-card" v-for="(member, i) in teamMembers" :key="i">
        <div class="team-details">
          <div class="image-wrapper">
            <v-avatar
              :size="64"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + member.profile_picture || imageAvatar
                })`,
              }"
            ></v-avatar>
          </div>
          <div class="details-wrap">
            <div class="dev-name">
              {{ member.name ? member.name : "" }}
              <span v-if="currentUser.id == member.id"> (You)</span>
            </div>
            <div class="dev-role">{{ member.team_role }}</div>
            <div class="dev-email">{{ member.email }}</div>
          </div>
        </div>
        <div class="card-action">
          <div class="text-action view-profile">View Profile</div>
          <div class="text-action swap-role" @click="handleSwapDev(member)">
            Swap Role
          </div>
          <div class="text-action remove-dev" @click="handleRemoveDev(member)">
            Remove/Replace
          </div>
        </div>
      </div>
    </div>
    <SwapTeamMember
      v-if="isSwapModal"
      v-bind="{ closeDialog, teamMembers, swapMember, handleSwapMember }"
    />
    <RemoveTeamMember
      v-if="isRemoveModal"
      v-bind="{
        closeDialog,
        teamMembers,
        removeMember,
        handleReplaceMember,
        otherMembers,
        replacement,
        spinner,
      }"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { replaceTeamInProject } from "@/api/team";
import RemoveTeamMember from "./removeTeamMember";
import SwapTeamMember from "./swapTeamMember";
export default {
  props: {
    teamMembers: {
      type: Array,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  components: {
    RemoveTeamMember,
    SwapTeamMember,
  },

  data: () => ({
    imageAvatar: "/img/onboarding/profile-avatar.svg",
    isRemoveModal: false,
    isSwapModal: false,
    swapMember: {},
    removeMember: {},
    otherMembers: [],
    replacement: "",
    spinner: false,
  }),

  created() {
    this.otherTeamMembers();
  },
  methods: {
    closeDialog() {
      this.isSwapModal = false;
      this.isRemoveModal = false;
    },
    handleSwapDev(obj) {
      this.swapMember = obj;
      this.isSwapModal = true;
    },
    handleRemoveDev(obj) {
      this.removeMember = obj;
      this.isRemoveModal = true;
    },
    handleReplaceMember() {
      let user_one = this.removeMember.id;
      let user_two = this.replacement;
      let project_id = this.$route.params.id;
      let payload = {
        user_one,
        user_two,
        project_id,
      };
      replaceTeamInProject(payload)
        .then((resp) => {
          // console.log(resp.data, "Done");
        })
        .catch((err) => {
          // console.log(err, "Error replacing team member");
        });
    },
    handleSwapMember() {},
    otherTeamMembers() {
      let teamMate = JSON.parse(JSON.stringify(this.devTeam.members));
      for (let i = 0; i < teamMate.length; i++) {
        for (let k = 0; k < this.teamMembers.length; k++) {
          if (teamMate[i].id == this.teamMembers[k].id) {
            teamMate.splice(i, 1);
          }
        }
      }
      this.otherMembers = teamMate;
      this.replacement = teamMate[0].id;
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    devTeam() {
      return this.teamInfo.team;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.manage-team-container {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  text-align: left;
}
.container-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.developers-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.developer-card {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px 24px;
  display: flex;
  width: 49%;
  margin-bottom: 24px;
  margin-right: 24px;
}
.developer-card:nth-child(even) {
  margin-right: 0px;
}
.team-details {
  padding: 16px 0px;
  width: 70%;
  display: flex;
}
.profile_bg {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-position: top;
  border: solid white;
  /* margin-right: 32px; */
}
.image-wrapper {
  width: 64px;
  height: 64px;
}
.details-wrap {
  margin-left: 16px;
  text-align: left;
}
.dev-name {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.dev-role {
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
}
.card-action {
  padding: 16px 0px;
  width: 30%;
  text-align: right;
  border-left: 1px solid #e4e5e7;
}
.text-action {
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  cursor: pointer;
}
.view-profile {
  color: #0781f2;
}
.swap-role {
  margin-top: 12px;
}
.remove-dev {
  color: #d45b54;
  margin-top: 12px;
}
@media (max-width: 1440px) {
  .developer-card {
    width: 48%;
  }
}
</style>
